import Input from '../../Field/Input/Input'
import { makeInitialValues } from '../../../common/utils'

const required = (value) => (value ? undefined : 'Required')

export const fields = [
  {
    component: Input,
    id: 'name',
    label: 'Name',
    name: 'name',
    placeholder: 'Type your name',
    validate: required,
  },
  {
    component: Input,
    id: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Type your e-mail',
    type: 'email',
    validate: required,
  },
]

export const initialValues = makeInitialValues(fields)
