import styled from 'styled-components'
import { Link } from 'react-scroll'

import { transition } from '../../common/mixins'

const StyledScrollToLabel = styled.div`
  margin-bottom: 0.75rem;
`

const StyledScrollToLabelTypo = styled.span`
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  text-transform: uppercase;
  letter-spacing: 6px;
  ${transition}
`

const StyledScrollToIcon = styled.div`
  display: flex;
  ${transition}
`

const StyledScrollTo = styled(Link)`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.colors.yellow};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.white};

    ${StyledScrollToIcon} {
      transform: translateY(0.5rem);
    }
  }
`

export {
  StyledScrollTo,
  StyledScrollToLabel,
  StyledScrollToLabelTypo,
  StyledScrollToIcon,
}
