import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledScrollTo,
  StyledScrollToLabel,
  StyledScrollToLabelTypo,
  StyledScrollToIcon,
} from './styles'
import ScrollArrowIcon from '../Icons/ScrollArrowIcon'

const ScrollTo = ({ title, scrollTarget }) => (
  <StyledScrollTo
    aria-label="Scroll down"
    to={scrollTarget}
    href={`#${scrollTarget}`}
    spy
    smooth
  >
    <StyledScrollToLabel>
      <StyledScrollToLabelTypo>{title}</StyledScrollToLabelTypo>
    </StyledScrollToLabel>
    <StyledScrollToIcon>
      <ScrollArrowIcon />
    </StyledScrollToIcon>
  </StyledScrollTo>
)

export default ScrollTo

ScrollTo.propTypes = {
  title: PropTypes.string.isRequired,
  scrollTarget: PropTypes.string,
}
