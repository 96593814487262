import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledCallMe,
  StyledCallMeInner,
  StyledCallMeInfo,
  StyledCallMeDecor,
  StyledCallMePhone,
} from './styles'
import DecorPhone from '../../assets/images/pic-decor-phone.svg'

const CallMe = ({ info, phone }) => (
  <StyledCallMe>
    <StyledCallMeInner className="uiAnimBottom">
      <StyledCallMeInfo>{info}</StyledCallMeInfo>
      <StyledCallMeDecor>
        <img src={DecorPhone} alt="Decor phone" />
      </StyledCallMeDecor>
      <StyledCallMePhone href={`tel:${phone.replace(/\s/g, '')}`}>
        {phone}
      </StyledCallMePhone>
    </StyledCallMeInner>
  </StyledCallMe>
)

export default CallMe

CallMe.propTypes = {
  info: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
}
