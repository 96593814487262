import React from 'react'

import { IconWrapper } from '../../common/mixins'

const DecorIcon = () => (
  <IconWrapper width="1.5845">
    <svg viewBox="0 0 396 250" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M385.6 129.3c-12.4-1.7-24.4-2.6-35.6-2.6-16 0-30.9 1.7-44.8 5.2 5.7-19.4 7.5-41.4 5.6-66.2l-.8-10.5-9.8 3.9c-23.4 9.4-42.5 20.9-57.5 34.8-4.9-27.5-17.6-55-38.5-83.4L198 2l-6.2 8.5c-20.9 28.4-33.6 56-38.5 83.5-15-13.9-34-25.4-57.5-34.8L86 55.3l-.8 10.5c-1.9 24.9-.1 46.8 5.6 66.2-13.9-3.5-28.8-5.2-44.8-5.2-11.2 0-23.2.9-35.6 2.6L0 130.8l4.5 9.5c18.7 39.7 42 67.5 71.4 84.9 25.7 15.3 56.4 22.8 93.9 22.8 8.9 0 18.2-.4 28.2-1.2 10 .8 19.3 1.2 28.3 1.2 37.5 0 68.2-7.4 93.9-22.8 29.3-17.5 52.7-45.3 71.4-84.9l4.5-9.5-10.5-1.5zM295.9 78c1 33.9-6.1 61.2-21.6 83.3-12 17.1-29.2 31.5-53.3 44.7 14.7-27.7 22.4-52.9 23.8-77.9.3-4.7.3-9.3.1-14 12.6-14.4 29.5-26.3 51-36.1zm-97.4-50c22.9 33.8 33.1 66.1 31.3 98.4-1.5 27.3-11.5 55.4-31.3 87.6-19.8-32.2-29.8-60.2-31.3-87.6-1.8-32.3 8.4-64.6 31.3-98.4zm-98.4 50c21.5 9.8 38.4 21.8 51 36.1-.2 4.7-.2 9.3.1 14 1.4 25 9.1 50.2 23.8 77.9-24.2-13.2-41.4-27.6-53.3-44.7-15.5-22.1-22.6-49.4-21.6-83.3zm69.8 154c-34.7 0-62.9-6.7-86.2-20.5-24.3-14.4-44.2-36.8-60.7-68.3 7.9-.8 15.5-1.2 22.8-1.2 18.9 0 36.1 2.6 51.7 7.8 3.2 7 7 13.5 11.4 19.8 16.1 22.7 40.1 41 75.4 57.4.9 1.5 1.8 2.9 2.6 4.5-5.8.3-11.5.5-17 .5zm142.4-20.5C289 225.3 260.8 232 226.1 232c-5.5 0-11.2-.2-17.1-.5.9-1.5 1.7-3 2.6-4.5 35.3-16.4 59.3-34.7 75.4-57.4 4.4-6.2 8.2-12.8 11.4-19.8 15.7-5.2 32.8-7.8 51.7-7.8 7.3 0 14.9.4 22.8 1.2-16.4 31.5-36.3 53.9-60.6 68.3z"
        fill="currentColor"
      />
    </svg>
  </IconWrapper>
)

export default DecorIcon
