import styled from 'styled-components'

import Container from '../../Container'
import media from '../../../common/MediaQueries'
import { Typo2 } from '../../Typography/Typography'

const StyledNewsletter = styled(Container)`
  position: relative;
  overflow: hidden;
  padding: 1.875rem 1.875rem 2rem 1.875rem;
  color: ${({ theme }) => theme.colors.black};

  @media ${media.phone} {
    padding-bottom: 4.5rem;
  }

  @media ${media.tablet} {
    padding: 3.125rem 3.125rem 6.5625rem 3.125rem;
  }
`

const StyledNewsletterHeader = styled(Typo2)`
  max-width: 35rem;
  width: 100%;
  margin-bottom: 1.5rem;
`

const StyledNewsletterContent = styled.div``

const StyledNewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @media ${media.phone} {
    flex-direction: row;
  }
`

const StyledNewsletterField = styled.div`
  flex: 1;
  margin-bottom: 0.9375rem;

  @media ${media.phone} {
    margin-right: 0.9375rem;
    margin-bottom: 0;
  }
`

const StyledNewsletterAction = styled.div`
  width: 100%;

  @media ${media.phone} {
    max-width: 12rem;
  }

  @media ${media.tablet} {
    max-width: 17.25rem;
  }
`

const StyledNewsletterDecor = styled.span`
  position: absolute;
  left: 50%;
  bottom: -1rem;
  transform: translateX(-50%);
  opacity: 0.14;
  color: ${({ theme }) => theme.colors.white};

  svg {
    font-size: 15.375rem;
  }

  @media ${media.phone} {
    left: auto;
    right: -0.5rem;
    transform: none;
  }
`

const StyledNewsletterSuccessMsg = styled(Container)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export {
  StyledNewsletter,
  StyledNewsletterHeader,
  StyledNewsletterContent,
  StyledNewsletterForm,
  StyledNewsletterField,
  StyledNewsletterAction,
  StyledNewsletterDecor,
  StyledNewsletterSuccessMsg,
}
