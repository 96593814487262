import styled from 'styled-components'

import media from '../common/MediaQueries'

const BlankAnchor = styled.span`
  display: block;
  content: '';
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: -${({ theme }) => theme.sizes.headerMobile};

  @media ${media.tablet} {
    top: -${({ theme }) => theme.sizes.header};
  }
`

export default BlankAnchor
