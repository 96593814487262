import React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Element } from 'react-scroll'
import { useInView } from 'react-intersection-observer'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import HeroSlider from '../components/Sliders/HeroSlider/HeroSlider'
import Inner from '../components/Inner'
import BlankAnchor from '../components/BlankAnchor'
import LatestNews from '../components/LatestNews/LatestNews'
import CallMe from '../components/CallMe/CallMe'
import Banners from '../components/Banners/Banners'
import Newsletter from '../components/Forms/Newsletter/Newsletter'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import { HEADER_TYPES } from '../constants/headerTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { newsletterSpacings, bgTheme } from '../common/utils'
import mapNewsToList from '../common/queries/mapNewsToList'
import mapBannersToList from '../common/queries/mapBannersToList'
import { Heading7 } from '../components/Typography/Typography'

const IndexPage = ({ data }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const [isSticky, setSticky] = useState(false)
  const { acf } = data.wp.page
  const seoData = data.wp.page?.seo
  const { edges: posts } = data.wp.posts
  const latestNews = mapNewsToList(posts)

  const newsletterSpacing = newsletterSpacings(acf.newsletterBoxMargins)
  const newsletterBg = bgTheme(acf.newsletterContainerBackground)

  const contentBlocks = acf.contentBlocks.content
  const newsListBg = bgTheme(acf.newsListBackground)

  const scrollListener = useCallback(() => {
    setSticky(window.pageYOffset > 60)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    window.addEventListener('touchmove', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
      window.removeEventListener('touchmove', scrollListener)
    }
  }, [scrollListener])

  const heroImages = acf.heroFields.images.map(
    ({ mobileX, image: { imageFile } }) => ({
      fluid: imageFile.childImageSharp.fluid,
      objectPosition: mobileX ? `${mobileX}% 50%` : '50% 50%',
    }),
  )

  const banners = mapBannersToList(acf)

  return (
    <Layout headerIsSticky={isSticky} headerType={HEADER_TYPES.sticky}>
      <SEO title={seoData?.title} data={seoData} />
      <HeroSlider
        title={acf.heroFields.title}
        subtitle={acf.heroFields.subtitle}
        images={heroImages}
        isScrollTo
      />

      <Container position="relative">
        <Element name="section">
          <BlankAnchor />
        </Element>
      </Container>

      <Container>
        <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
        <Container
          pt={newsletterSpacing}
          pb={newsletterSpacing}
          bg={newsletterBg}
          overflow="hidden"
        >
          <Inner pl={['0', '1.875rem']} pr={['0', '1.875rem']}>
            <Newsletter />
          </Inner>
        </Container>
      </Container>

      <Container mb={['1rem', '3.75rem']} overflow="hidden">
        <Inner>
          <Banners banners={banners} />
        </Inner>
      </Container>

      <CallMe info={acf.ctaBarText} phone={acf.ctaBarPhone} />

      <Container
        inView={inView}
        pt={['2.5rem', '3.375rem']}
        pb={['2.5rem', '3.375rem']}
        overflow="hidden"
        bg={newsListBg}
        color={
          newsListBg === BG_TYPES.white || newsListBg === BG_TYPES.lightGrey
            ? 'black'
            : 'white'
        }
      >
        <Inner>
          <Container mb="1.5rem" className="uiAnimBottom">
            <Heading7 as="h3" textAlign="center">
              {acf.newsListTitle}
            </Heading7>
          </Container>
          <div ref={ref}>
            {inView ? (
              <LatestNews
                description={acf.newsListDesc}
                news={latestNews}
                action
              />
            ) : null}
          </div>
        </Inner>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    wp {
      models(first: 20) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 555) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              ...modelDetailsFragment
              comingSoon
            }
          }
        }
      }
      page(id: "home", idType: URI) {
        id
        seo {
          ...seoFragment
        }
        slug
        title
        acf {
          newsletterBoxMargins
          newsletterContainerBackground
          ctaBarText
          ctaBarPhone
          newsListTitle
          newsListBackground
          newsListDesc
          ...bannersFragment
          heroFields {
            title
            subtitle
            images {
              image {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                altText
              }
              mobileX
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Acf_ContentBlocks_Content_Welcome {
                fieldGroupName
                bg
                title
                enableVideo
                videoCincopaId
                videoImagePlaceholder {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                content
                enableAction
                action {
                  showAction
                  action {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Acf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Acf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Acf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
                title
                subtitle
                action {
                  showAction
                  action {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      posts(first: 9) {
        edges {
          node {
            ...postFragment
          }
        }
      }
    }
  }
`
