import { BUTTON_TYPES } from '../../constants/buttonTypes'
import { buildAction } from '../utils'

const mapBannersToList = (acf) => {
  const { underNewsletterBoxLeft, underNewsletterBoxRight } = acf

  let bannerLeftAction
  let bannerRightAction

  if (underNewsletterBoxLeft?.action?.showAction !== null) {
    bannerLeftAction = buildAction({
      ...underNewsletterBoxLeft?.action?.action,
      btnType: BUTTON_TYPES.secondary,
    })
  }

  if (underNewsletterBoxRight?.action?.showAction !== null) {
    bannerRightAction = buildAction({
      ...underNewsletterBoxRight?.action?.action,
      btnType: BUTTON_TYPES.secondary,
    })
  }

  const bannerLeft = {
    title: underNewsletterBoxLeft?.title,
    description: underNewsletterBoxLeft?.content,
    action: bannerLeftAction,
    fluid: underNewsletterBoxLeft?.bgImage?.imageFile?.childImageSharp.fluid,
  }

  const bannerRight = {
    title: underNewsletterBoxRight?.title,
    description: underNewsletterBoxRight?.content,
    action: bannerRightAction,
    fluid: underNewsletterBoxRight?.bgImage?.imageFile?.childImageSharp.fluid,
  }

  return [bannerLeft, bannerRight]
}

export default mapBannersToList
