import React from 'react'

import { IconWrapper } from '../../common/mixins'

const ScrollArrowIcon = () => (
  <IconWrapper width="1.375">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12">
      <path
        d="M0 1l11 10L22 1"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </IconWrapper>
)

export default ScrollArrowIcon
