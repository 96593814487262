import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  StyledNewsletter,
  StyledNewsletterHeader,
  StyledNewsletterContent,
  StyledNewsletterForm,
  StyledNewsletterField,
  StyledNewsletterAction,
  StyledNewsletterDecor,
  StyledNewsletterSuccessMsg,
} from './styles'

import { Body } from '../../Typography/Typography'
import Button from '../../Buttons/Button/Button'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import DecorIcon from '../../Icons/DecorIcon'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { fields, initialValues } from './mock'
import { useOnSubmitHandler } from '../form'

const Newsletter = () => {
  const { isLoading, isSent, onSubmit } = useOnSubmitHandler(
    'ajax_save_newsletter_submission',
  )

  return (
    <Form
      {...{ initialValues }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <StyledNewsletter
          bg="yellow"
          className="uiAnimBottom"
          onSubmit={handleSubmit}
        >
          {isSent ? (
            <StyledNewsletterSuccessMsg textAlign="center">
              <Body fontWeight="bold">
                You have successfully subscribed to the newsletter
              </Body>
            </StyledNewsletterSuccessMsg>
          ) : (
            <>
              <StyledNewsletterHeader
                fontWeight="bold"
                fontSize="1.5rem"
                as="h3"
              >
                Subscribe to discover our newest top models, receive elite offers, and gain unique insights into exceptional high-end companionship!
              </StyledNewsletterHeader>
              <StyledNewsletterContent>
                <StyledNewsletterForm>
                  {fields.map(
                    ({
                      component: Component,
                      id,
                      name,
                      label,
                      placeholder,
                      type,
                      validate,
                    }) => (
                      <StyledNewsletterField key={name}>
                        <Field name={name} validate={validate}>
                          {({ input, meta }) => (
                            <Component
                              fieldType={FIELD_TYPES.newsletter}
                              id={id}
                              label={label}
                              placeholder={placeholder}
                              type={type}
                              validate={meta.touched && meta.error}
                              disabled={isLoading}
                              message={meta.touched && meta.error}
                              {...input}
                            />
                          )}
                        </Field>
                      </StyledNewsletterField>
                    ),
                  )}
                  <StyledNewsletterAction>
                    <Button
                      btnType={BUTTON_TYPES.simple}
                      decor={false}
                      disabled={isLoading}
                    >
                      Join Now
                    </Button>
                  </StyledNewsletterAction>
                </StyledNewsletterForm>
              </StyledNewsletterContent>
              <StyledNewsletterDecor>
                <DecorIcon />
              </StyledNewsletterDecor>
            </>
          )}
        </StyledNewsletter>
      )}
    />
  )
}

export default Newsletter
