// React Slick

import React from 'react'
import PropTypes from 'prop-types'

import Slider from '../Slider/Slider'
import {
  StyledHeroSliderWrapper,
  StyledHeroSlider,
  StyledHeroSliderPreTitle,
  StyledHeroSliderTitle,
  StyledHeroSliderInner,
  StyledHeroSliderMedia,
  StyledHeroSliderAction,
} from './styles'
import defaultSliderOptions from '../../../constants/defaultSliderConfig'
import { fluidPropType } from '../../../constants/proptypes'
import Image from '../../Image/Image'
import ScrollTo from '../../ScrollTo/ScrollTo'

const sliderOptions = {
  ...defaultSliderOptions,
  fade: true,
  speed: 5000,
  autoplay: true,
  slidesToShow: 1,
  arrows: false,
}

const HeroSlider = ({ images, subtitle, title, isScrollTo }) => (
  <StyledHeroSliderWrapper>
    <StyledHeroSlider>
      <StyledHeroSliderInner className="uiAnimBottom">
        <StyledHeroSliderPreTitle>{subtitle}</StyledHeroSliderPreTitle>
        <StyledHeroSliderTitle as="h1" color="yellow">
          {title}
        </StyledHeroSliderTitle>
      </StyledHeroSliderInner>
      <StyledHeroSliderMedia>
        <Slider
          sliderOptions={sliderOptions}
          slidesArray={images}
          slideComponent={Image}
        />
      </StyledHeroSliderMedia>
    </StyledHeroSlider>
    {isScrollTo && (
      <StyledHeroSliderAction>
        <ScrollTo title="scroll" scrollTarget="section" />
      </StyledHeroSliderAction>
    )}
  </StyledHeroSliderWrapper>
)

export default HeroSlider

HeroSlider.propTypes = {
  images: PropTypes.arrayOf(fluidPropType).isRequired,
  isScrollTo: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
