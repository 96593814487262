import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { Heading7, Body } from '../Typography/Typography'

const StyledBannersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 1.875rem * 2);

  @media ${media.phone} {
    margin: -1.875rem -0.9375rem 0 -0.9375rem;
    width: calc(100% + 1.875rem);
  }
`

const StyledBannersItem = styled.li`
  width: 100%;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  @media ${media.phone} {
    width: calc(50% - 1.875rem);
    margin: 1.875rem 0.9375rem 0 0.9375rem;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const StyledBanner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2.5rem 2.125rem 3.125rem 2.125rem;
  background: ${({ theme }) => theme.colors.black};

  &::before {
    z-index: 1;
    content: '';
    display: block;
    border: 1px solid ${({ theme }) => rgba(theme.colors.yellow, 0.23)};
    position: absolute;
    top: 0.9375rem;
    left: 0.9375rem;
    right: 0.9375rem;
    bottom: 0.9375rem;
  }

  @media ${media.tablet} {
    padding: 3.125rem;
    min-height: 22.25rem;

    &::before {
      top: 1.25rem;
      left: 1.25rem;
      right: 1.25rem;
      bottom: 1.25rem;
    }
  }
`

const StyledBannerImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledBannerContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`

const StyledBannerTitle = styled(Heading7)`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.yellow};
`

const StyledBannerDesc = styled(Body)`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
`

export {
  StyledBannersList,
  StyledBannersItem,
  StyledBanner,
  StyledBannerImg,
  StyledBannerTitle,
  StyledBannerDesc,
  StyledBannerContent,
}
