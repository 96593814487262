import styled from 'styled-components'

import Inner from '../Inner'
import { transition } from '../../common/mixins'
import media, { customMedia } from '../../common/MediaQueries'

const StyledCallMe = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`

const StyledCallMeInner = styled(Inner)`
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const StyledCallMeInfo = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.5px;
  line-height: 1.7;
  margin-bottom: 1.25rem;

  @media ${media.tablet} {
    margin-bottom: 0;
    margin-right: 2rem;
  }

  @media ${customMedia.inner} {
    margin-right: 0;
  }
`
const StyledCallMeDecor = styled.div`
  display: none;

  @media ${customMedia.inner} {
    display: flex;
    flex-shrink: 0;
    max-width: 17.5rem;
    width: 100%;
  }
`

const StyledCallMePhone = styled.a`
  display: flex;
  flex-shrink: 0;
  font-size: 1.75rem;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  color: ${({ theme }) => theme.colors.yellow};
  line-height: 1.2;
  letter-spacing: 1.75px;
  ${transition}

  &:hover {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`

export {
  StyledCallMe,
  StyledCallMeInner,
  StyledCallMeInfo,
  StyledCallMeDecor,
  StyledCallMePhone,
}
