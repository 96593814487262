import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import {
  StyledBannersList,
  StyledBannersItem,
  StyledBanner,
  StyledBannerImg,
  StyledBannerTitle,
  StyledBannerDesc,
  StyledBannerContent,
} from './styles'
import Image from '../Image/Image'
import { fluidPropType } from '../../constants/proptypes'

const Banners = ({ banners }) => (
  <StyledBannersList className="uiAnimBottom">
    {banners.map(({ title, description, action, fluid }, index) => (
      <StyledBannersItem key={index}>
        <StyledBanner>
          <StyledBannerImg>
            <Image {...{ fluid }} />
          </StyledBannerImg>
          <StyledBannerContent>
            <StyledBannerTitle as="h3">{title}</StyledBannerTitle>
            <StyledBannerDesc>{description}</StyledBannerDesc>
            {action}
          </StyledBannerContent>
        </StyledBanner>
      </StyledBannersItem>
    ))}
  </StyledBannersList>
)

export default Banners

Banners.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      fluid: PropTypes.oneOfType([fluidPropType, PropTypes.object]),
      action: PropTypes.node,
    }),
  ),
}

export const query = graphql`
  fragment bannersFragment on wp_Page_Acf {
    underNewsletterBoxLeft {
      title
      content
      bgImage {
        mediaItemUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 570) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      action {
        showAction
        action {
          internalOrExternal
          external {
            target
            title
            url
          }
          internal {
            title
            target {
              ... on wp_Post {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Page {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Model {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
            }
          }
        }
      }
    }
    underNewsletterBoxRight {
      title
      content
      bgImage {
        mediaItemUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 570) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      action {
        showAction
        action {
          internalOrExternal
          external {
            target
            title
            url
          }
          internal {
            title
            target {
              ... on wp_Post {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Page {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
              ... on wp_Model {
                contentType {
                  node {
                    name
                  }
                }
                slug
              }
            }
          }
        }
      }
    }
  }
`
