import styled from 'styled-components'
import { rgba } from 'polished'

import Inner from '../../Inner'
import media from '../../../common/MediaQueries'
import { Heading1 } from '../../Typography/Typography'

const StyledHeroSliderWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.black};
  text-align: center;
  display: flex;

  &::before {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      transparent,
      ${({ theme }) => rgba(theme.colors.black, 0.9)}
    );
  }
`

const StyledHeroSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .slick-slider,
  .slick-list {
    height: 100%;
  }

  .slick-track {
    display: flex;
    height: 100%;
    left: 0 !important;
  }

  @media ${media.tablet} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`

const StyledHeroSliderInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;

  @media ${media.tablet} {
    max-width: 100%;
  }
`

const StyledHeroSliderPreTitle = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  margin-bottom: 1rem;

  @media ${media.phone} {
    font-size: 2.5rem;
  }

  @media ${media.tablet} {
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
  }
`

const StyledHeroSliderTitle = styled(Heading1)``

const StyledHeroSliderMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    opacity: 0.9;
  }
`

const StyledHeroSliderAction = styled.div`
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media ${media.phone} {
    bottom: 6rem;
  }
`

export {
  StyledHeroSliderWrapper,
  StyledHeroSlider,
  StyledHeroSliderPreTitle,
  StyledHeroSliderTitle,
  StyledHeroSliderInner,
  StyledHeroSliderMedia,
  StyledHeroSliderAction,
}
